import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { isDesktop } from 'react-device-detect';
// eslint-disable-next-line import/no-cycle
import RenderContentItem from '../renderContentItem';
import './slideShow.scss';

function SlideShow(props) {
  const {
    speedType,
    cmsContentItems,
    type,
    enableAutoPlay,
    trackTags,
    disableAutoPlay,
    isMobilePhone,
    animationSpeed = 500,
    transitionTime = 7000,
    siteTickerUpdate,
  } = props;
  const animationSpeedType = speedType?.split(' ')[2].toLowerCase();

  // eslint-disable-next-line no-unused-vars
  const [longestTextWidth, setLongestTextWidth] = useState(0);
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);
  const characterSpace = 10;

  useEffect(() => {
    function calculateMaxCharacterCount() {
      let maxCharacterCount = 0;

      document.querySelectorAll('.slick-slide').forEach((slide) => {
        const paragraph = slide.querySelector('.rich-text.list p');
        const text = paragraph ? paragraph.textContent : null;

        if (text !== null) {
          const characterCount = text.length;
          maxCharacterCount = Math.max(maxCharacterCount, characterCount);
        }
      });

      return maxCharacterCount;
    }

    setLongestTextWidth(calculateMaxCharacterCount() * characterSpace);
  }, []);

  let playSpeed = transitionTime;
  if (animationSpeedType === 'slow') {
    playSpeed = 3000;
  } else if (animationSpeedType === 'fast') {
    playSpeed = 500;
  }

  function a11y() {
    const slider = document.querySelectorAll('.site-ticker .slick-slide');
    if (!slider.length) return;
    slider.forEach((slide) => {
      if (slide.querySelector('a')) {
        slide.querySelector('a').removeAttribute('tabindex');
        if (slide.getAttribute('aria-hidden') === 'true') {
          slide.querySelector('a').setAttribute('tabindex', '-1');
        }
      }
    });
  }

  const settings = {
    autoplaySpeed: playSpeed,
    autoplay: speedType === 'Carousel Animation Custom' && isMobilePhone && disableAutoPlay ? false : enableAutoPlay,
    arrows: siteTickerUpdate ? isSliderLoaded : false,
    className: type,
    fade: true,
    speed: animationSpeed,
    cssEase: 'ease-in-out',
    pauseOnFocus: true,
    pauseOnHover: true,
  };

  const sliderStyle = {
    'max-width': longestTextWidth > 0 && (longestTextWidth > 1600 ? 1600 : longestTextWidth),
    margin: '0 auto',
  };

  const handleSliderInit = () => {
    setIsSliderLoaded(true);
  };

  return (
    <div className="slider-container" style={(siteTickerUpdate && isDesktop) ? sliderStyle : null}>
      <Slider {...settings} onInit={handleSliderInit} onReInit={a11y}>
        {cmsContentItems.map((contentItem) => (
          <RenderContentItem
            trackTags={trackTags}
            cmsContentItem={contentItem}
            key={contentItem.sys.id}
          />
        ))}
      </Slider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  enableAutoPlay: get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true',
  siteTickerUpdate: get(state, 'toggles.NMO_ADA_HEADER', false),
});

export default connect(mapStateToProps)(SlideShow);
